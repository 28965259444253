@font-face {
	font-family: "kindengezin";
	src: url("/assets/icons/kindengezin.089989cd2a7395729a9a0c487a950b1f.eot?#iefix") format("embedded-opentype"),
url("/assets/icons/kindengezin.58ac4fa32b2c5ca18aae13116074b595.woff") format("woff"),
url("/assets/icons/kindengezin.fe4919489a16e67b8759fcdd04eec2b6.woff2") format("woff2"),
url("/assets/icons/kindengezin.a55b77d764479d9a781b628a95d891d9.ttf") format("truetype"),
url("/assets/icons/kindengezin.b37dae2b4148510b1106d2f92529fd94.svg#kindengezin") format("svg");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: kindengezin !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon.add-user:before {
	content: "\F101";
}
.icon.back:before {
	content: "\F102";
}
.icon.backspace:before {
	content: "\F103";
}
.icon.calendar:before {
	content: "\F104";
}
.icon.camera:before {
	content: "\F105";
}
.icon.checkmark:before {
	content: "\F106";
}
.icon.chevron-down:before {
	content: "\F107";
}
.icon.chevron-right:before {
	content: "\F108";
}
.icon.cross:before {
	content: "\F109";
}
.icon.edit:before {
	content: "\F10A";
}
.icon.error:before {
	content: "\F10B";
}
.icon.hourglass:before {
	content: "\F10C";
}
.icon.house:before {
	content: "\F10D";
}
.icon.info:before {
	content: "\F10E";
}
.icon.keypad:before {
	content: "\F10F";
}
.icon.menu:before {
	content: "\F110";
}
.icon.next:before {
	content: "\F111";
}
.icon.previous:before {
	content: "\F112";
}
.icon.questionmark:before {
	content: "\F113";
}
.icon.rotate-camera:before {
	content: "\F114";
}
.icon.search:before {
	content: "\F115";
}
.icon.success:before {
	content: "\F116";
}
.icon.three-dots:before {
	content: "\F117";
}
.icon.time:before {
	content: "\F118";
}
.icon.trash:before {
	content: "\F119";
}
.icon.triangle-left:before {
	content: "\F11A";
}
.icon.triangle-right:before {
	content: "\F11B";
}
.icon.upload-fail:before {
	content: "\F11C";
}
.icon.upload-success:before {
	content: "\F11D";
}
