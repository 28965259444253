@font-face {
    font-family: 'Flanders Art';
    src: url(/assets/fonts/95ffdf355030be5f973505869318476b.eot);
    src: url(/assets/fonts/95ffdf355030be5f973505869318476b.eot?#iefix) format('embedded-opentype'), 
    url(/assets/fonts/f91515c105b07b113cc8d84756a6ec97.woff) format('woff'), 
    url(/assets/fonts/ef4c84051c8e0d34988c32bfe6533757.ttf) format('truetype'), 
    url(/assets/e93baa2f30a9e40b6c33d9c2425061fe.svg#d0ebe09b8d309cd8a8986065f592a85e) format('svg');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Flanders Art';
    src: url(/assets/fonts/32f98fca839ee766b92520d8af2ffce1.eot);
    src: url(/assets/fonts/32f98fca839ee766b92520d8af2ffce1.eot?#iefix) format('embedded-opentype'), 
    url(/assets/fonts/8cfb4970e46ee18fe321b9a168ceb19e.woff) format('woff'), 
    url(/assets/fonts/de1326341d05993dfb332b4090f1e5f5.ttf) format('truetype'), 
    url(/assets/15b33ef2718f0e8d737239162bfc4d37.svg#d0ebe09b8d309cd8a8986065f592a85e) format('svg');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Flanders Art';
    src: url(/assets/fonts/849c666abeffb2d5591368a7680a40c2.eot);
    src: url(/assets/fonts/849c666abeffb2d5591368a7680a40c2.eot?#iefix) format('embedded-opentype'), 
    url(/assets/fonts/9b20962d721efa47377969ccf28a27db.woff) format('woff'), 
    url(/assets/fonts/f9d1426687d07c1f28811d88ac0a85a1.ttf) format('truetype'), 
    url(/assets/52feabca0f505c842b732aa3bded0c23.svg#d0ebe09b8d309cd8a8986065f592a85e) format('svg');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'Flanders Art';
    src: url(/assets/fonts/a3b9fc44386a27fc1563e97c3c92b9a0.eot);
    src: url(/assets/fonts/a3b9fc44386a27fc1563e97c3c92b9a0.eot?#iefix) format('embedded-opentype'), 
    url(/assets/fonts/c457b248dcf7adda9c0e0986e50ce729.woff) format('woff'), 
    url(/assets/fonts/2d39ac3607191bf18251002b819016e7.ttf) format('truetype'), 
    url(/assets/584306e2b5057acb9a10b82647eee1aa.svg#d0ebe09b8d309cd8a8986065f592a85e) format('svg');
    font-style: normal;
    font-weight: 700;
}