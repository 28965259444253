body,
html {
    height: 100%;
    font-family: "Flanders Art", sans-serif !important;
    background: #F5F5F5 !important;
    font-size: 14px !important;
}

* {
    box-sizing: border-box;
}

@media print {
    body,
    html {
        background-color: white !important;
        width: 100%;
    }
}

#app-container {
    height: 100vh;
}

.moreActionsPopover .ant-popover-arrow {
    background-color: rgb(37, 185, 189);
    box-shadow: none !important;
}

.moreActionsPopover .ant-popover-inner {
    background-color: rgb(37, 185, 189);
    box-shadow: none !important;
    padding-top: 15px;
}

.moreActionsPopover .item {
    display: flex;
    color: white;
    font-weight: 200;
    width: 200px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.moreActionsPopover .item:hover {
    opacity: .7;
}

.moreActionsPopover .item .icon {
    font-size: 10px;
}

.moreActionsPopover .item:not(:last-of-type) {
    margin-bottom: 8px;
}


/* NEW SHIT */

.hiddenButtonsPopover .ant-popover-arrow {
    background-color: rgb(37, 185, 189);
    margin-right: 6px;
}

.hiddenButtonsPopover .ant-popover-inner {
    background-color: rgb(37, 185, 189);
}

.hiddenButtonsPopover .item {
    display: flex;
    color: white;
    font-weight: 200;
    align-items: center;
    /* justify-content: flex-end; */
    cursor: pointer;
}

.hiddenButtonsPopover .item:hover {
    opacity: .7;
}

.hiddenButtonsPopover .item .icon {
    font-size: 10px;
}

.hiddenButtonsPopover .item:not(:last-of-type) {
    margin-bottom: 8px;
}

.ant-popover {
    z-index: 1000 !important;
}
